import React, { Component } from "react";
import { Auth } from "aws-amplify";
import Card from '@material-ui/core/Card';
import Login from './LoginForm'
import LoginOTPForm from './LoginOTPForm'
import ForgotPassword from './ForgotPassword';
import axios from 'axios';
import Loader from './loader'
import './style.css';
import FirstTimePasswordChange from './FirstTimePasswordChange';
import { saveLogin, getPublicIP, getTokenForEmployer } from './utils';
import { Modal } from "react-bootstrap";
import styles from "../../Assets/CSS/stylesheet_UHS";
import customStyle from "../../Assets/CSS/stylesheet_UHS";
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button";
import awsConfig from '../../awsConfig';

const CustomButton = withStyles(
  customStyle.viewBtn
)(Button);
export class SignIn extends Component {
  constructor(props) {
    super(props);
    let emailToReset = sessionStorage.getItem('emailToReset');

    this.state = {
      username: "",
      password: "",

      signedin: false,
      confirmationCode: "",
      forgotPass: emailToReset ? true : false,
      firstTimepwdRest: false,
      sendVerification: false,
      verificationCode: '',
      sendMFA: false,
      user: {},
      errorMesssage: '',
      showLoader: false,
      agreeModal: false,
      passwordfirsttime: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
    this.handleSendVerification = this.handleSendVerification.bind(this);

    this.confirmSignIn = this.confirmSignIn.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    getPublicIP();
  }

  async checkStatus() {
    const authUser = await Auth.signIn({
      username: this.state.username,
      password: this.state.password,
    }).catch(err => {
      console.log('test', err);
      // alert(err.message)
      this.setState({
        errorMesssage: '',
        cognitoErr: 'These credentials are no longer valid. Use the sign in link in the most recent email.',
        disableSiginBtn: false,
        msgModal: true
      })
      this.toggleLoader(false)

    });
  }
  componentDidMount() {

    getTokenForEmployer().then((response) => {
      let token = response['headers'].authorization;
      let request = {
        "cognitoUserPool": awsConfig.aws_user_pools_id,
        "username": this.state.username
      }
      let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
      axios.post(process.env.REACT_APP_csr_base_url + 'memberportal/getuser', request, {
        headers: {
          Authorization: token,
          'X-Tenant-Id': netwellOrganization,
        }
      }).then((resp) => {
        if (resp.data.userStatus == "FORCE_CHANGE_PASSWORD") {
          this.checkStatus();
        }
      }).catch((err) => {
        console.log('check', err);
      })
    }).catch((err) => {
      console.log('rest', err);
    })
    this.toggleLoader(true);
    this.NetwellgetGateWayToken()
    Auth.currentSession().then((session) => {
      let token = this.parseJwt(session.idToken.jwtToken);
      localStorage.setItem('isLogged', 'true');
      this.toggleLoader(false);
      saveLogin(token.email);
      this.props.history.push('/');
    }).catch((error) => {
      console.log('inside get current user')
      this.toggleLoader(false);
      localStorage.setItem('isLogged', 'false');
      document.body.classList.add('bodyColor');
    });
  }

  parseJwt = (id_token) => {

    let base64Url = id_token.split('.')[1];

    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    let jsonPayload = decodeURIComponent(

      atob(base64)

        .split('')

        .map(function (c) {

          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)

        })

        .join('')

    );

    let token = JSON.parse(jsonPayload);
    return token;
  };

  componentWillUnmount() {
    document.body.classList.remove('bodyColor');
  }
  handleChange(e, val) {
    console.log('change')
    this.setState({
      [e.target.name]: e.target.value
    });
    if (val)
      this.props.history.push('/login');
  }

  async handleSubmit(e) {
    console.log('submit')
    e.preventDefault();
    this.toggleLoader(true)
    const { signedin, username, password, user } = this.state;
    this.setState({
      errorMesssage: ''
    })
    /*let email = await getProperEmailId(username);
*/
    console.log('username:::', username, '   -   ',password);
    try {
    //method will signin the user and return current user with session
    const authUser = await Auth.signIn({
      username: username,
      password: password,
    }).catch(err => {
      console.log('test', err);
      // alert(err.message)
      this.setState({
        errorMesssage: 'Incorrect username or password.',
        cognitoErr: 'These credentials are no longer valid. Use the sign in link in the most recent email.',
        disableSiginBtn: false,
        // msgModal: true
      })
      this.toggleLoader(false)

    });
    this.toggleLoader(false)
    if (authUser) {
      console.log('======================= authUser ==================');
      console.log(authUser);

      if (authUser.challengeName === 'SMS_MFA' ||
        authUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.setState({
          user: authUser,
          sendMFA: true,
          disableSiginBtn: false
        })
      } else if (authUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({
          user: authUser,
          disableSiginBtn: false,
          firstTimepwdRest: true,
        })
      } else {
        // window.location.reload()
        this.setState({
          disableSiginBtn: false
        });
        //window.location.reload()
        saveLogin(username)
        sessionStorage.setItem('isLogged', false);
        // this.setState({agreeModal:true})
        this.props.history.push('/employer')
      }
    }
  } catch (err) {
    console.error('Sign-in error:', err);
    this.setState({
        errorMesssage: err && String(err).match("Pending sign-in attempt") ? String(err).replace("Error:", "Please try to sign-in again.") :'Incorrect username or password.',
        cognitoErr: 'These credentials are no longer valid. Use the sign-in link in the most recent email.',
        disableSiginBtn: false
    });
    } finally {
      this.toggleLoader(false);
      this.setState({ loading: false });
    }
  }


  handleForgot(e) {
    e.preventDefault();
    // const {forgotPass } = this.state;
    this.setState({
      forgotPass: true
    })

  };




  handleSendVerification(e) {
    e.preventDefault();
    const { username } = this.state;
    Auth.forgotPassword(username)
      .then(data => console.log(data))
      .catch(err => console.log(err));
    this.setState({
      sendVerification: true
    })
  }



  NetwellgetGateWayToken = () => {
    let request = {
      "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
      "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
    }
    console.log('req', process.env);
    let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
    axios.post(process.env.REACT_APP_BASEURL + "v1/login", request, {
      headers: {
        'X-Tenant-Id': netwellOrganization,
      }
    }).then(response => {
      if (response.data.message) {
        console.log("failed to get token")
        alert(response.data.message)
      }
      else {
        let bearer = response['headers'].authorization
        var array = bearer.split("Bearer ")
        localStorage.setItem("NetwellbearerToken", array[1]);
      }
    });
  }



  async confirmSignIn(e) {
    e.preventDefault();
    const { verificationCode, sendMFA, signedin, user } = this.state;
    this.toggleLoader(true);

    const loggedUser = await Auth.confirmSignIn(
      user,   // Return object from Auth.signIn()
      verificationCode,   // Confirmation code  
      "SMS_MFA"
      // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(() => {
      console.log('sign in confirm success')
      this.toggleLoader(false);
      this.props.history.push('/')


    }).catch(err => {
      console.log(err);
      this.toggleLoader(false);
      this.setState({
        errorMesssage: err.message
      })

      // alert(err.message)
    });



  }





  // btnclick() {
  //   console.log(Auth.currentAuthenticatedUser());
  //   window.open("GET https://localhost:3000/logout?client_id=3uu8mib69gappsn13lv62ims2l&logout_uri=http://localhost:3000/");
  //   console.log(Auth.currentAuthenticatedUser());
  // }



  async handleLogout() {
    console.log('trying to logout')
    let curUser = await Auth.currentAuthenticatedUser();
    console.log(curUser)
    await Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    curUser = await Auth.currentAuthenticatedUser();
    console.log(curUser)
    this.setState({ agreeModal: false })
  }

  toggleLoader = (value) => {
    this.setState({
      showLoader: value
    })
  }
  getVIew() {
    /*const { signedin, forgotPass, sendVerification, verificationCode, sendMFA, user, firstTimepwdRest} = this.state;
      if (forgotPass) {
        return (
            <ForgotPassword confirmSignIn={this.confirmSignIn} toggleLoader={this.toggleLoader}/>
        )
      }
      else if (sendMFA) {

        return (
            <LoginOTPForm user={user} handleChange={this.handleChange} confirmSignIn={this.confirmSignIn} errorMesssage={this.state.errorMesssage} />
        )
        // return (
        //   <Card style={{ padding: "20px", marginTop: "50px" }}>
        //     <form >
        //       <div class="container">
        //         <h1>Confirm Login</h1>
        //         <p>Please fill in this form to confirm login.</p>
        //         <hr />
        //         <TableContainer component={Paper}>
        //           <Table aria-label="simple table">

        //             <TableRow>
        //               <TableCell>
        //                 <label for="verification code">
        //                   <b>verificationCode</b>
        //                 </label></TableCell>
        //               <TableCell> <input
        //                 type="text"
        //                 placeholder="Enter verificationCode"
        //                 name="verificationCode"
        //                 required
        //                 onChange={this.handleChange}
        //               /></TableCell>
        //               <TableCell>
        //                 <button type="submit" class="signupbtn" onClick={this.confirmSignIn} >
        //                   confirm signIn
        //           </button></TableCell>
        //             </TableRow>
        //           </Table>
        //         </TableContainer>
        //       </div>
        //     </form>


        //   </Card>

        //)

      }
      else if (signedin) {
        return (
            <Card style={{ padding: "20px", marginTop: "50px" }}>
              welcome you are signed in
              <button onClick={this.handleLogout}>logout</button>
            </Card>
        )

      } else {
        return (

            <Login handleSubmit={this.handleSubmit} handleChange={this.handleChange} handleForgot={this.handleForgot} handleToggle={this.props.handleToggle} errorMsg={this.state.errorMesssage} disableSiginBtn={this.state.disableSiginBtn} />

        );
      }*/
    const { signedin, forgotPass, sendVerification, verificationCode, sendMFA, user, firstTimepwdRest, username, passwordfirsttime } = this.state;
    if (forgotPass) {
      return (
        <ForgotPassword onChangePassword={(password, email) => this.onChangePassword(password, email)} confirmSignIn={this.confirmSignIn} toggleLoader={this.toggleLoader} gotoLoginScreen={this.gotoLoginScreen} />
      )
    }
    else if (sendMFA) {

      return (
        <LoginOTPForm user={user} handleChange={this.handleChange} confirmSignIn={this.confirmSignIn} errorMesssage={this.state.errorMesssage} handleSubmit={this.handleSubmit} />
      )
    }
    else if (signedin) {
      return (
        <Card style={{ padding: "20px", marginTop: "50px" }}>
          welcome you are signed in
          <button onClick={this.handleLogout}>logout</button>
        </Card>
      )

    } else if (firstTimepwdRest) {
      return (
        <FirstTimePasswordChange onChangePassword={(password, email) => this.onChangePassword(password, email)} email={username} user={user} confirmSignIn={this.confirmSignIn} toggleLoader={this.toggleLoader} />
      )
    } else {
      return (

        <Login userName={this.state.username} password={this.state.password} handleSubmit={this.handleSubmit} handleChange={this.handleChange} handleForgot={this.handleForgot} handleToggle={this.props.handleToggle} errorMsg={this.state.errorMesssage} disableSiginBtn={this.state.disableSiginBtn} />

      );
    }

  }
  onChangePassword = (password, email) => {
    getTokenForEmployer().then((response) => {
      let token = response['headers'].authorization;
      let request = {
        "cognitoUserPool": awsConfig.aws_user_pools_id,
        "username": this.state.username
      }
      let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
      axios.post(process.env.REACT_APP_csr_base_url + 'memberportal/getuser', request, {
        headers: {
          Authorization: token,
          'X-Tenant-Id': netwellOrganization,
        }
      }).then((resp) => {
        console.log('resp,', resp.data);
        if (resp.data.userStatus == 'CONFIRMED') {
          this.setState({ msgModal: true, cognitoErr: 'Your password has been successfully changed. You can now use your new credentials to access the portal. Click Sign In to continue.' })
        }
      }).catch((err) => {
        console.log('check', err);
      })
    }).catch((err) => {
      console.log('rest', err);
    })
    console.log('new pass');
    this.state.password = ''
    this.setState({ passwordfirsttime: password, showLoader: false, password, username: email, passChanged: true })
  }
  gotoLoginScreen = () => {
    this.setState({
      forgotPass: false,
      firstTimepwdRest: false,
      signedin: false,
      sendMFA: false
    })
  }
  closeModal() {
    this.setState({ agreeModal: false })
    let curUser = Auth.currentAuthenticatedUser();
    console.log(curUser)
    Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    curUser = Auth.currentAuthenticatedUser();
    console.log(curUser)
  }
  render() {
    return (
      <>
        {this.getVIew()}
        <Loader showLoader={this.state.showLoader} />

        <Modal size={'md'} show={this.state.msgModal} onHide={(event) => this.setState({ msgModal: false, loaderShow: false })} centered backdrop='static'>
          <Modal.Header style={styles.modal_header} closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={customStyle.HomeContainer}>
              <>
                <div style={customStyle.HomeMAinChild}>
                  {this.state.cognitoErr}
                </div>
              </>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ msgModal: false, loaderShow: false }, () => {if (this.state.passChanged) {
              window.location.href = "?username="+ this.state.username +"&password="+ this.state.password
              this.state.firstTimepwdRest = false
              }})}>Ok</CustomButton>
          </Modal.Footer>
        </Modal>

      </>
    )

  }
}

export default SignIn;
